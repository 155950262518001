const setCredentials = (email, password) => {
  localStorage.setItem('email', email);
  localStorage.setItem('password', password);
}

const getCredentials = () => {
  return {
    email: localStorage.getItem('email'),
    password: localStorage.getItem('password'),
  };
}

const clearCredentials = () => setCredentials('', '');

const CredentialsUtil = {
  setCredentials,
  getCredentials,
  clearCredentials,
};

export default CredentialsUtil;
