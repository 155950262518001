import ApiUtil from './api';

const createClient = async (userId, name) => {
  try {
    const requestData = { userId, name };
    const responseData = await ApiUtil.post('/api/admin/clients', null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const updateClient = async (clientId, name) => {
  try {
    const requestData = { name };
    const responseData = await ApiUtil.put(`/api/admin/clients/${clientId}`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listClients = async () => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/clients`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listUserClients = async (userId) => {
  try {
    const requestQuery = { userId };
    const responseData = await ApiUtil.get(`/api/admin/clients`, requestQuery, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getClient = async (clientId) => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/clients/${clientId}`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const ClientUtil = {
  createClient,
  updateClient,
  
  listClients,
  getClient,
  
  listUserClients,
};

export default ClientUtil;
