import ApiUtil from './api';
import CredentialUtils from './credentials';

const host = ApiUtil.getHost();

const isLoggedIn = () => {
  const { email, password } = CredentialUtils.getCredentials();
  if (email == null || email === '' || password == null || password === '') {
    return false;
  }

  return true;
}

const login = (email, password) => {
  CredentialUtils.setCredentials(email, password);
}

const logout = () => {
  CredentialUtils.clearCredentials();
}

const register = async (email, password, firstName, lastName, organization) => {
  try {
    const response = await fetch(`${host}/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        organization,
      }),
    });
    
    const body = await response.json();
    return body;
    
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getBasicAuthHeader = () => {
  const { email, password } = CredentialUtils.getCredentials();
  if (email == null || email === '' || password == null || password === '') {
    throw new Error('Not logged in');
  }

  const raw = `${email}:${password}`;
  const basicToken = btoa(raw);

  return `Basic ${basicToken}`;
};

const AuthUtil = {
  login,
  logout,
  isLoggedIn,

  register,

  getAuthHeader: getBasicAuthHeader,
};

export default AuthUtil;
