import OutlineButtonSmall from '../outlineButtonSmall';

import './style.css';

const BalanceCardView = ({ client, balance, showTopUp = true }) => {
  if (balance == null) {
    return (
      <div class='balancecardview-root'>
        <label>No balance</label>
      </div>
    )
  }

  return (
    <div className='balancecardview-root'>
      <label className='balancecardview-label'>Balance - {client.name}</label>
      <h2 className='balancecardview-value'>{balance.currency} {balance.amount.toFixed(2)}</h2>

      <div className='balancecardview-action-container'>
        { showTopUp && <OutlineButtonSmall onClick={() => window.alert('Please contact us for top ups')}>Top Up</OutlineButtonSmall> }
      </div>
    </div>
  )
};

export default BalanceCardView;