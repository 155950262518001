import './style.css';

const Page = () => {

  return (
    <div className="paymentmethods-root">
      <h2>Payment Methods</h2>
    </div>
  )
};

export default Page;