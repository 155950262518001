import './style.css';

const Panel = ({ onClose, allowClosePanel = true, children }) => {
  const createNullContent = () => (
    <div>
      Sorry, we do not have content here to show.
    </div>
  );

  return (
    <div className='panel-root'>
      <div className='panel-nav-container'>
        { allowClosePanel && <div className='panel-nav-close' onClick={onClose}>Close Panel</div> }
      </div>
      { children || createNullContent() }
    </div>
  );
}

export default Panel;