import './style.css';

const ImageButtonSmall = ({ onClick, src, alt, width = 16, height = 16 }) => {
  return (
    <div className='image-button-outline-small-root' onClick={onClick}>
      <img src={src} width={width} height={height} alt={alt} />
    </div>
  );
}

export default ImageButtonSmall;