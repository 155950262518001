import ApiUtil from './api';

const getBalance = async (clientId) => {
  try {
    const responseData = await ApiUtil.get(`/api/clients/${clientId}/balances/default`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const BalanceUtil = {
  getBalance,
};

export default BalanceUtil;
