import './style.css';

const ClientCardView = ({ client, showUser = false, onClick, onUserClick }) => {
  if (client == null) {
    return <div></div>
  }

  const {
    id: clientId,
    userId,
    name,
    dateCreated,
    dateUpdated,
  } = client;

  return (
    <div className='clientcardview-root' onClick={onClick}>
      <div className='clientcardview-field'>
        <div className='clientcardview-value'>{name}</div>
      </div>
      
      <div className='clientcardview-field'>
        <label className='clientcardview-label-small'>Client ID:&nbsp;{clientId || 'N/A'}</label>
      </div>
      
      { showUser && <div className='clientcardview-field'>
        <label className='clientcardview-label-small'>User ID:&nbsp;<span className='clientcardview-clickable' onClick={onUserClick}>{userId || 'N/A'}</span></label>
      </div> }
      
      <div className='clientcardview-field-small'>
        <label className='clientcardview-label-small'>Created At:&nbsp;{dateCreated}</label>
      </div>
      
      <div className='clientcardview-field-small'>
        <label className='clientcardview-label-small'>Updated At:&nbsp;{dateUpdated}</label>
      </div>
    </div>
  );
}

export default ClientCardView;