import { createSlice } from "@reduxjs/toolkit";

export const balanceSlice = createSlice({
  name: 'balance',
  initialState: {
    currentBalance: null,
    clientBalance: null,
  },
  reducers: {
    setCurrentBalance: (state, action) => {
      state.currentBalance = action.payload;
    },
    setClientBalance: (state, action) => {
      state.clientBalance = action.payload;
    },
  },
});

export const { 
  setCurrentBalance,
  setClientBalance,
} = balanceSlice.actions;

export default balanceSlice.reducer;
