import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BackContentLayout from '../../../layouts/backContentLayout';
import Loading from '../../../components/loading';
import ClientCardView from '../../../components/clientCardView';
import ClientCardNew from '../../../components/clientCardNew';
import ClientCardSecretView from '../../../components/clientCardSecretView';
import OutlineButtonSmall from '../../../components/outlineButtonSmall';

import { setClients } from '../../../services/client/clientSlice';
import { showPanel, closePanel } from '../../../services/panel/panelSlice';

import ClientUtil from '../../../utils/clients';

import './style.css';

const MAX_COUNT = 30;

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clients = useSelector(state => state.client.clients);
  const [loadingClients, setLoadingClients] = useState(false);

  const reloadClients = useCallback(async () => {
    setLoadingClients(true);
    const multiClientResponse = await ClientUtil.listClients(MAX_COUNT, 0);
    setLoadingClients(false);

    const { ok, status, body } = multiClientResponse;
    if (ok) {
      dispatch(setClients(body.clients));
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [setLoadingClients, dispatch, navigate]);

  const createClient = useCallback(async (editableClient) => {
    setLoadingClients(true);
    dispatch(closePanel())
    
    const clientResponse = await ClientUtil.createClient(editableClient.name);
    const { ok, status, body } = clientResponse;
    if (ok) {
      dispatch(showPanel({
        allowClosePanel: true,
        panelContent: <ClientCardSecretView client={body} />
      }));
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }

    setLoadingClients(false);

    reloadClients();
  }, [reloadClients, dispatch, navigate]);

  const showCreateClientPanel = useCallback(() => {
    if (clients && clients.length >= MAX_COUNT) {
      window.alert('You have reach the maximum allowed number of clients.');
      return;
    }

    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: <ClientCardNew onCreate={createClient} />
    }));
  }, [clients, dispatch, createClient]);

  useEffect(() => {
    reloadClients();
  }, [reloadClients]);

  return (
    <BackContentLayout>
      <h1>
        <span>My Clients</span>
        <span className='label-new-button'><OutlineButtonSmall onClick={showCreateClientPanel}>New Client</OutlineButtonSmall></span>
      </h1>
      
      <Loading loading={loadingClients} />
      
      { !loadingClients && clients.map(client => <ClientCardView
        client={client}
        key={client.id}
        onClick={() => navigate(`/clients/${client.id}`)}
        onUserClick={() => {}}
        />
      )}
    </BackContentLayout>
  )
};

export default Page;