import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BackContentLayout from '../../../layouts/backContentLayout';
import Loading from '../../../components/loading';
import UserCardEdit from '../../../components/userCardEdit';

import { setMyUser } from '../../../services/user/userSlice';

import UserUtil from '../../../utils/users';

import './style.css';

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const myUser = useSelector(state => state.user.myUser);
  const [loadingUser, setLoadingUser] = useState(false);

  const reloadUser = useCallback(async () => {
    setLoadingUser(true);
    const userResponse = await UserUtil.getMyUser();
    setLoadingUser(false);

    const { ok, status, body } = userResponse;
    if (ok) {
      dispatch(setMyUser(body));
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [setLoadingUser, dispatch, navigate]);

  const updateUser = useCallback(async (editableUser) => {
    setLoadingUser(true);
    const userResponse = await UserUtil.updateMyUser(editableUser.firstName, editableUser.lastName, editableUser.organization, editableUser.email);

    const { status } = userResponse;
    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
    setLoadingUser(false);

    await reloadUser();
  }, [reloadUser, navigate]);

  useEffect(() => {
    reloadUser();
  }, [reloadUser]);

  return (
    <BackContentLayout>
      <h1>Update My User</h1>
      <Loading loading={loadingUser} />
      { !loadingUser && myUser && <UserCardEdit user={myUser} onUpdate={updateUser} /> }
    </BackContentLayout>
  )
};

export default Page;