import { useState } from 'react';

import TextButton from '../textButton';

import './style.css';

const ClientCardRouteSplitEdit = ({ routeSplit, onUpdate, onDelete, availableDeliveryMethods }) => {
  const [editableRouteSplit, setEditableRouteSplit] = useState(routeSplit);

  return (
    <div className='clientcardroutesplitedit-root'>
      <div className='clientcardroutesplitedit-field'>
        <label className='clientcardroutesplitedit-label'>Delivery Method:</label>
        <select className='clientcardroutesplitedit-select' 
          onChange={e => setEditableRouteSplit(prevClient => ({ ...prevClient, deliveryMethod: e.target.value}))}
          value={editableRouteSplit.deliveryMethod || ''}>
          { availableDeliveryMethods && availableDeliveryMethods.map(deliveryMethod => (
            <option className='clientcardroutesplitedit-option' key={deliveryMethod}>
              { deliveryMethod }
            </option>
          ))}
        </select>
      </div>

      <div className='clientcardroutesplitedit-field'>
        <label className='clientcardroutesplitedit-label'>Weight:</label>
        <input type='text' className='clientcardroutesplitedit-input' onChange={e => setEditableRouteSplit(prevClient => ({ ...prevClient, weight: e.target.value}))} value={editableRouteSplit.weight || ''} />
      </div>

      
      <div className='clientcardroutesplitedit-field'>
        <label className='clientcardroutesplitedit-label-small'>Route Split ID:&nbsp;{routeSplit.id || 'N/A'}</label>
      </div>

      
      <div className='clientcardroutesplitedit-field'>
        <label className='clientcardroutesplitedit-label-small'>Client ID:&nbsp;{routeSplit.clientId || 'N/A'}</label>
      </div>
      
      <div className='clientcardroutesplitedit-field'>
        <label className='clientcardroutesplitedit-label-small'>User ID:&nbsp;{routeSplit.userId || 'N/A'}</label>
      </div>
      

      <div className='clientcardroutesplitedit-field'>
        <button className='clientcardroutesplitedit-button' onClick={() => onUpdate(editableRouteSplit)}>Update</button>
        <TextButton onClick={() => onDelete(editableRouteSplit)}>Delete</TextButton>
      </div>
    </div>
  );
}

export default ClientCardRouteSplitEdit;