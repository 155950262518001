import { useState } from 'react';

import './style.css';

const UserCardEdit = ({ user, onUpdate }) => {
  const [editableUser, setEditableUser] = useState(user);

  if (user == null) {
    return <div>Null User</div>
  }

  return (
    <div className='usercardedit-root'>
      <div className='usercardedit-field'>
        <label className='usercardedit-label'>First Name:</label>
        <input type='text' className='usercardedit-input' onChange={e => setEditableUser(prevUser => ({ ...prevUser, firstName: e.target.value}))} value={editableUser.firstName || ''} />
      </div>

      <div className='usercardedit-field'>
        <label className='usercardedit-label'>Last Name:</label>
        <input type='text' className='usercardedit-input' onChange={e => setEditableUser(prevUser => ({ ...prevUser, lastName: e.target.value}))} value={editableUser.lastName || ''} />
      </div>
      
      <div className='usercardedit-field'>
        <label className='usercardedit-label'>Organization:</label>
        <input type='text' className='usercardedit-input' onChange={e => setEditableUser(prevUser => ({ ...prevUser, organization: e.target.value}))} value={editableUser.organization || ''} />
      </div>
      
      <div className='usercardedit-field'>
        <label className='usercardedit-label'>Email:</label>
        <input type='text' className='usercardedit-input' onChange={e => setEditableUser(prevUser => ({ ...prevUser, email: e.target.value}))} value={editableUser.email || ''}/>
      </div>
      
      <div className='usercardedit-field'>
        <label className='usercardedit-label-small'>User ID: {editableUser.id || 'N/A'}</label>
      </div>

      <div className='usercardedit-field'>
        <button className='usercardedit-button' onClick={() => onUpdate(editableUser)}>Update</button>
      </div>
    </div>
  );
}

export default UserCardEdit;