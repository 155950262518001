import { useState } from 'react';

import OutlineButtonSmall from '../outlineButtonSmall';
import ImageButtonSmall from '../imageButtonSmall';

import './style.css';

const ClientCardSecretView = ({ client }) => {
  const [showSecret, setShowSecret] = useState();

  const copyToClipboard = (value) => navigator.clipboard.writeText(value);

  return (
    <div className='clientcardsecretview-root'>
      <div className='clientcardsecretview-icon'>
        <img src='/success.png' width={80} height={80} alt='success' />
      </div>
      
      <div className='clientcardsecretview-field'>
        <label className='clientcardsecretview-label'>Name</label>
        <div className='clientcardsecretview-value'>{client.name || 'N/A'}</div>
      </div>

      <div className='clientcardsecretview-field'>
        <label className='clientcardsecretview-label'>
          <span className='clientcardsecretview-span-with-right-margin'>Client ID</span>
          <span><ImageButtonSmall src='/copy.png' alt='copy' width={16} height={16} onClick={() => copyToClipboard(client.id)} /></span>
        </label>
        <div className='clientcardsecretview-value'>{client.id || 'N/A'}</div>
      </div>

      <div className='clientcardsecretview-banner'>
        <label className='clientcardsecretview-banner-label'>Warning</label>
        <p>This secret will only be shown once in this page. We will not be able to recover the secret once you close or refresh this page.</p>
        <p>If you lose or forget this secret, you will not be able to recover it. When that happens, you can reset and get a new secret instead.</p>
      </div>
      
      <div className='clientcardsecretview-field'>
        <label className='clientcardsecretview-label'>
          <span className='clientcardsecretview-span-with-right-margin'>Client Secret</span>
          <span><ImageButtonSmall src='/copy.png' alt='copy' width={16} height={16} onClick={() => copyToClipboard(client.secret)} /></span>
        </label>
        <div className='clientcardsecretview-value'>
          <span className='clientcardsecretview-span-with-right-margin'>{showSecret ? (client.secret || 'N/A') : '⬤⬤⬤⬤⬤⬤'}</span>
          <span><OutlineButtonSmall onClick={() => setShowSecret(value => !value)}>{showSecret ? 'Hide' : 'Show'}</OutlineButtonSmall></span>
        </div>
      </div>
    </div>
  );
}

export default ClientCardSecretView;