import { createHashRouter, Navigate } from 'react-router-dom';

import Login from './pages/auth/login';
import Register from './pages/auth/register';

import Main from './pages/main';
import QuickStart from './pages/main/quickStart';
import Billing from './pages/main/billing';
import Messaging from './pages/main/messaging';

import EditMyUser from './pages/main/users';
import ChangeMyUserPassword from './pages/main/userPassword';
import ViewClients from './pages/main/clients';
import ViewClient from './pages/main/client';

import Balance from './pages/main/billing/balance';
import PaymentMethods from './pages/main/billing/paymentMethods';

import Sms from './pages/main/messaging/sms';

import AdminMain from './pages/adminMain';
import AdminClient from './pages/adminMain/client';
import AdminClients from './pages/adminMain/clients';
import AdminUser from './pages/adminMain/user';
import AdminUsers from './pages/adminMain/users';


import NotFoundPage from './pages/notFound';

const router = createHashRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/admin',
    element: <AdminMain />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        element: <Navigate to='/admin/clients' replace />
      },
      {
        path: '/admin/clients',
        element: <AdminClients />,
      },
      {
        path: '/admin/clients/:clientId',
        element: <AdminClient />,
      },
      {
        path: '/admin/users/',
        element: <AdminUsers />,
      },
      {
        path: '/admin/users/:userId',
        element: <AdminUser />,
      }
    ],
  },
  {
    path: '/',
    element: <Main />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        element: <Navigate to='/billing' replace />
      },
      {
        path: '/quickstart',
        element: <QuickStart />,
      },
      {
        path: '/users/me',
        element: <EditMyUser />,
      },
      {
        path: '/users/me/password',
        element: <ChangeMyUserPassword />,
      },
      {
        path: '/clients',
        element: <ViewClients />,
      },
      {
        path: '/clients/:clientId',
        element: <ViewClient />,
      },
      {
        path: '/billing',
        element: <Billing />,
        children: [
          {
            index: true,
            element: <Navigate to='/billing/balance' replace />
          },
          {
            path: '/billing/balance',
            element: <Balance />,
          },
          {
            path: '/billing/paymentMethods',
            element: <PaymentMethods />, 
          }
        ]
      },
      {
        path: '/messaging',
        element: <Messaging />,
        children: [
          {
            index: true,
            element: <Navigate to='/messaging/sms' replace />
          },
          {
            path: '/messaging/sms',
            element: <Sms />,
          },
        ]
      },
    ],
  }
]);

export default router;