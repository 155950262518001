import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import BackContentLayout from '../../../layouts/backContentLayout';

import Loading from '../../../components/loading';
import ClientCardView from '../../../components/clientCardView';

import ClientUtils from '../../../utils/adminClients';

import './style.css';

const LOAD_COUNT = 30;

const Page = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);

  const listNextClients = useCallback(async (loadCount, loadOffset) => {
    setLoadingClients(true);
    const multiClientResponse = await ClientUtils.listClients(loadCount, loadOffset);
    setLoadingClients(false);
    
    const { ok, status, body } = multiClientResponse;
    if (ok) {
      const { clients: responseClients } = body;
      setClients(responseClients);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [navigate, setClients, setLoadingClients]);

  useEffect(() => {
    console.log('Loading clients');
    listNextClients(LOAD_COUNT, 0);
  }, [listNextClients]);

  return (
    <BackContentLayout>
      <h1>Clients</h1>
      <Loading loading={loadingClients} />
      
      { !loadingClients && clients.map(client => <ClientCardView 
        client={client} 
        key={client.id} 
        showUser={true}
        onClick={() => navigate(`/admin/clients/${client.id}`)}
        onUserClick={() => navigate(`/admin/users/${client.userId}`)}
        />) }

    </BackContentLayout>
  )
};

export default Page;