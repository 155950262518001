import { useNavigate } from 'react-router-dom';

import DynamicSidebarExpand from '../dynamic-sidebar-expand';
import DynamicSidebarItem from '../dynamic-sidebar-item';

import './style.css';

const SideBar = ({ items = [] }) => {
  const navigate = useNavigate();

  const onItemClick = (item) => {
    if (item.url && item.url !== '') {
      navigate(item.url);
      return;
    }

    if (item.alert && item.alert !== '') {
      window.alert(item.alert);
      return;
    }

    window.alert('Sorry, this is not available yet and will be coming soon.');
  };

  const createExpand = (item, key) => {
    return (
      <DynamicSidebarExpand key={key} name={item.name}>
        { item.children.map((child, i) => createChild(child, `${key}_${i}`)) }
      </DynamicSidebarExpand>
    );
  };

  const createItem = (item, key) => {
    return (
      <DynamicSidebarItem key={key} 
        name={item.name}
        onItemClick={() => onItemClick(item)} />
    );
  };

  const createComponent = (item, key) => {
    return (
      <div key={key}>
        { item.component }
      </div>
    );
  }

  const createChild = (item, key) => {
    const { type } = item;

    if (type === 'component') {
      return createComponent(item, key);
    } else if (type === 'expand') {
      return createExpand(item, key);
    } else if (type === 'item') {
      return createItem(item, key);
    }

    return createItem(item, key);
  }

  return (
    <div className='sidebar-root'>
      { items.map((item, i) => createChild(item, i)) }
    </div>
  );
}

export default SideBar;