import './style.css';

const UserCardView = ({ user, onClick }) => {
  if (user == null) {
    return <div></div>
  }

  const {
    id: userId,
    firstName,
    lastName,
    organization,
    email,
    dateCreated,
    dateUpdated,
  } = user;

  return (
    <div className='usercardview-root' onClick={onClick}>
      <div className='usercardview-field'>
        <label className='usercardview-label'>Name:</label>
        <div className='usercardview-value'>{firstName} {lastName}</div>
      </div>
      
      <div className='usercardview-field'>
        <label className='usercardview-label'>Organization:</label>
        <div className='usercardview-value'>{organization || 'N/A'}</div>
      </div>
      
      <div className='usercardview-field'>
        <label className='usercardview-label'>Email:</label>
        <div className='usercardview-value'>{email || 'N/A'}</div>
      </div>
      
      <div className='usercardview-field'>
        <label className='usercardview-label-small'>User ID: {userId || 'N/A'}</label>
      </div>
      
      <div className='usercardview-field-small'>
        <label className='usercardview-label-small'>Created At: {dateCreated}</label>
      </div>
      
      <div className='usercardview-field-small'>
        <label className='usercardview-label-small'>Updated At: {dateUpdated}</label>
      </div>
    </div>
  );
}

export default UserCardView;