import SidebarBalance from '../../../components/sidebarBalance';

import SidebarLayout from '../index';

const CustomLayout = ({ children }) => {
  const sidebarItems = [
    { type: 'component', component: <SidebarBalance /> },
    { type: 'item', name: 'Quick Start', url: '/quickstart'  },
    { type: 'item', name: 'Messaging', url: '/messaging'  },
    { 
      type: 'expand', name: 'Billing',
      children: [
        { type: 'item', name: 'Balance', url: '/billing/balance' },
        { type: 'item', name: 'Payment Methods', url: '/billing/paymentMethods' },
      ]
    },
    { type: 'item', name: 'Insights' },
  ];

  return (
    <SidebarLayout sidebarItems={sidebarItems}>
      {children}
    </SidebarLayout>
  )
};

export default CustomLayout;