import ApiUtil from './api';

const updateMyUser = async (firstName, lastName, organization, email) => {
  try {
    const requestData = {
      firstName,
      lastName,
      organization,
      email,
    };
    const responseData = await ApiUtil.put(`/api/users/me`, null, requestData, false);
    return responseData;    
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const updateMyUserPassword = async (currentPassword, newPassword) => {
  try {
    const requestData = {
      currentPassword,
      newPassword,
    };
    const responseData = await ApiUtil.put(`/api/users/me/password`, null, requestData, false);
    return responseData;    
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getMyUser = async () => {
  try {
    const responseData = await ApiUtil.get(`/api/users/me`, null, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const UserUtil = {
  updateMyUser,
  updateMyUserPassword,
  
  getMyUser,
};

export default UserUtil;
