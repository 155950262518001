import { Outlet } from 'react-router-dom';

import './style.css';

const Page = () => {

  return (
    <div className="messaging-root">
      <Outlet />
    </div>
  )
};

export default Page;