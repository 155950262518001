import { useNavigate } from 'react-router-dom';

import './style.css';

const Layout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="back-content-layout-root">
      <div className='back-button-container'>
        <div className='back-button' onClick={() => navigate(-1)}>&#9664; Back</div>
      </div>
      
      <div className='back-content-layout-container'>
        { children }
      </div>
    </div>
  )
};

export default Layout;