import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TabLayout from '../../../../layouts/tabLayout';
import { showTab } from '../../../../services/tab/tabSlice';

import TopUpHistoryTab from './topupHistory';
import UsageHistoryTab from './usageHistory';

import './style.css';

const Page = () => {
  const tabs = useMemo(() => [
    { id: 'billing.balance#topup_history', name: 'Top-up History' },
    { id: 'billing.balance#usage_history', name: 'Usage History' },
  ], []);

  const dispatch = useDispatch();
  const selectedTab = useSelector(state => state.tab.selectedTab);
  
  const onTabClicked = (tab) => {
    dispatch(showTab(tab.id));
  };

  useEffect(() => {
    dispatch(showTab(tabs[0].id));
  }, [dispatch, tabs]);

  return (
    <div className="balance-root">
      <h2 className='balance-header'>Balance</h2>
      <TabLayout tabs={tabs} selectedTab={selectedTab} onTabClicked={onTabClicked}>
        { selectedTab === 'billing.balance#topup_history' && <TopUpHistoryTab /> }
        { selectedTab === 'billing.balance#usage_history' && <UsageHistoryTab /> }
      </TabLayout>
    </div>
  )
};

export default Page;