import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BackContentLayout from '../../../layouts/backContentLayout';
import Loading from '../../../components/loading';
import ClientCardView from '../../../components/clientCardView';
import ClientCardEdit from '../../../components/clientCardEdit';
import ClientCardSecretView from '../../../components/clientCardSecretView';

import OutlineButton from '../../../components/outlineButton';

import { setClient } from '../../../services/client/clientSlice';

import ClientUtil from '../../../utils/clients';

import './style.css';
import { closePanel, showPanel } from '../../../services/panel/panelSlice';

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const client = useSelector(state => state.client.client);
  const [loadingClient, setLoadingClient] = useState(false);
  const [clientEditMode, setClientEditMode] = useState(false);

  const reloadClient = useCallback(async () => {
    setLoadingClient(true);
    const clientResponse = await ClientUtil.getClient(clientId);
    setLoadingClient(false);

    const { ok, status, body } = clientResponse;
    if (ok) {
      dispatch(setClient(body));
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [clientId, setLoadingClient, dispatch, navigate]);

  const updateClient = useCallback(async (editableClient) => {
    setLoadingClient(true);
    await ClientUtil.updateClient(editableClient.id, editableClient.name);
    setLoadingClient(false);

    setClientEditMode(false);
    await reloadClient();
  }, [reloadClient]);

  const resetClientSecret = useCallback(async () => {
    dispatch(closePanel());

    const confirm = window.confirm('Are you sure you want to reset the secret? The existing secret will no longer be usable');
    if (!confirm) {
      return;
    }

    const clientResponse = await ClientUtil.resetClientSecret(client.id);
    const { ok, status, body } = clientResponse;
    if (ok) {
      dispatch(showPanel({
        allowClosePanel: true,
        panelContent: <ClientCardSecretView client={body} />
      }));
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [dispatch, client, navigate]);

  const deleteClient = useCallback(async () => {
    const confirm = window.confirm('Are you sure you want to permanently delete the client?');
    if (!confirm) {
      return;
    }

    const clientResponse = await ClientUtil.deleteClient(client.id);
    const { ok, status } = clientResponse;
    if (ok) {
      window.alert('The client has been deleted');
      navigate('/clients');
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [navigate, client]);

  useEffect(() => {
    reloadClient();
  }, [reloadClient]);

  return (
    <BackContentLayout>
      <h1>My Client</h1>
      <Loading loading={loadingClient} />
      
      { !loadingClient && !clientEditMode && client && <ClientCardView
        client={client}
        key={client.id}
        onClick={() => setClientEditMode(true)}
        onUserClick={() => {}}
        />
      }

      {
        !loadingClient && clientEditMode && client && <ClientCardEdit
        client={client}
        onUpdate={updateClient} 
        onCancel={() => setClientEditMode(false)}
        />
      }

      <h2>Access and Secret</h2>
      <div className='client-banner'>
        <p>We are only able to reveal the secret once when you first created or resetted the secret. If you lose or forget this secret, you will not be able to recover it.</p>
        <p>When that happens, you can reset and get a new secret instead.</p>
      </div>
      <div className='client-button'>
        <OutlineButton onClick={resetClientSecret}>Reset Secret</OutlineButton>
      </div>

      
      <h2>Delete Client</h2>
      <div className='client-banner client-banner-danger'>
        <p>This is an irreversible action. Once deleted we will not be able to recover the client.</p>
      </div>
      <div className='client-button'>
        <OutlineButton onClick={deleteClient}>Delete Client</OutlineButton>
      </div>
    </BackContentLayout>
  )
};

export default Page;