import { useState } from 'react';

import './style.css';

const UserPasswordCardEdit = ({ onUpdatePassword }) => {
  const [editableUserPassword, setEditableUserPassword] = useState({ 
    currentPassword: '', 
    newPassword: '',
    confirmPassword: '',
  });

  return (
    <div className='userpasswordcardedit-root'>
      <div className='userpasswordcardedit-field'>
        <label className='userpasswordcardedit-label'>Current Password:</label>
        <input type='password' className='userpasswordcardedit-input' onChange={e => setEditableUserPassword(prevUserPassword => ({ ...prevUserPassword, currentPassword: e.target.value}))} value={editableUserPassword.currentPassword || ''} />
      </div>

      <div className='userpasswordcardedit-field'>
        <label className='userpasswordcardedit-label'>New Password:</label>
        <input type='password' className='userpasswordcardedit-input' onChange={e => setEditableUserPassword(prevUserPassword => ({ ...prevUserPassword, newPassword: e.target.value}))} value={editableUserPassword.newPassword || ''} />
      </div>
      
      <div className='userpasswordcardedit-field'>
        <label className='userpasswordcardedit-label'>Confirm New Password:</label>
        <input type='password' className='userpasswordcardedit-input' onChange={e => setEditableUserPassword(prevUserPassword => ({ ...prevUserPassword, confirmPassword: e.target.value}))} value={editableUserPassword.confirmPassword || ''} />
      </div>

      <div className='userpasswordcardedit-field'>
        <button disabled={editableUserPassword.newPassword !== editableUserPassword.confirmPassword} className='userpasswordcardedit-button' onClick={() => onUpdatePassword(editableUserPassword)}>Update Password</button>
      </div>
    </div>
  );
}

export default UserPasswordCardEdit;