import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NavbarLayout from '../index';
import NavbarDropdown from '../../../components/navbarDropdown';
import NavbarDropdownItem from '../../../components/navbarDropdownItem';

import AuthUtil from '../../../utils/auth';

import './style.css';

const CustomLayout = ({ children }) => {
  const navigate = useNavigate();

  const myUser = useSelector(state => state.user.myUser);

  const logout = () => {
    const confirm = window.confirm('Are you sure you want to logout?');
    if (!confirm) {
      return;
    }

    AuthUtil.logout();
    navigate('/login');
  }

  const navbarChildren = () => [
    <h1 key='navbar-left' className='navbar-label' onClick={() => navigate('/')}>AdminHub</h1>,
    
    <div key='navbar-middle' className='navbar-middle-container'></div>,
    
    <NavbarDropdown key='navbar-right' button={`Hello ${myUser ? myUser.firstName : 'user'}`}>
      <NavbarDropdownItem onClick={() => logout()}>Logout</NavbarDropdownItem>
    </NavbarDropdown>,
  ];

  return (
    <NavbarLayout navbarChildren={navbarChildren()}>
      {children}
    </NavbarLayout>
  )
};

export default CustomLayout;