import AuthUtil from './auth';

const host = `https://api.commlab.com.sg`;

const getHost = () => host;

const getUrl = (path, query) => {
  let url = `${host}${path}`;

  if (query == null || Object.keys(query).length <= 0) {
    // No Query
    return url;
  }

  // Have Query
  const searchParams = new URLSearchParams(query);
  return `${url}?${searchParams.toString()}`;
}

const callApi = async (method, path, query, data, ignoreResponse = false) => {
  try {
    const url = getUrl(path, query);
    const response = await fetch(url, {
      method,
      headers: {
        'Authorization': AuthUtil.getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: data && JSON.stringify(data),
    });

    if (ignoreResponse) {
      return;
    }

    const { ok, status } = response;
    let responseData = await response.text();

    if (responseData != null && responseData !== '') {
      responseData = JSON.parse(responseData);
    }
    
    if (!ok) {
      console.error(`Received ${status} for ${url}`);
    }

    return {
      ok, // True for 2xx, False for 3xx, 4xx
      status,
      body: responseData,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}


const getApi = async (path, query, ignoreResponse = false) => callApi('GET', path, query, null, ignoreResponse);
const postApi = async (path, query, data, ignoreResponse = false) => callApi('POST', path, query, data, ignoreResponse);
const putApi = async (path, query, data, ignoreResponse = false) => callApi('PUT', path, query, data, ignoreResponse);
const patchApi = async (path, query, data, ignoreResponse = false) => callApi('PATCH', path, query, data, ignoreResponse);
const deleteApi = async (path, query, data, ignoreResponse = false) => callApi('DELETE', path, query, data, ignoreResponse);

const ApiUtil = {
  getHost,

  get: getApi,
  post: postApi,
  put: putApi,
  patch: patchApi,
  delete: deleteApi,
};

export default ApiUtil;
