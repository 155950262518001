import './style.css';

const Component = ({ children, onClick }) => {
  const onItemClick = e => {
    if (onClick != null) {
      onClick(e);
    }
  }
  return (
    <div className='dropdown-item' onClick={onItemClick}>
      { children }
    </div>
  )
};

export default Component;