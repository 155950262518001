import SideBar from '../../components/dynamic-sidebar';

import './style.css';

const Layout = ({ children, sidebarItems }) => {
  return (
    <div className="sidebar-layout-root">
      <SideBar items={sidebarItems} />
      { children }
    </div>
  )
};

export default Layout;