import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loading from '../loading';
import OutlineButtonSmall from '../outlineButtonSmall';

import { setCurrentBalance } from '../../services/balance/balanceSlice';
import { showTab } from '../../services/tab/tabSlice';

import BalanceUtil from '../../utils/balance';

import './style.css';

const Component = ({ showViewDetails = true, showTopUp = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const balance = useSelector(state => state.balance.currentBalance);
  const client = useSelector(state => state.client.currentClient);

  const [loading, setLoading] = useState(false);

  const loadBalance = useCallback(async () => {
    setLoading(true);
    if (client == null) {
      setLoading(false);
      return;
    }
    const balanceResponse = await BalanceUtil.getBalance(client.id);
    setLoading(false);

    const { ok, status, body } = balanceResponse;
    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
    if (ok) {
      dispatch(setCurrentBalance(body));
    }
  }, [dispatch, navigate, client]);

  useEffect(() => {
    loadBalance();
  }, [client, loadBalance]);

  if (loading) {
    return (
      <div className='sidebarbalance-root'>
        <Loading loading={loading}/>
      </div>
    )
  }

  if (balance == null) {
    return (
      <div class='sidebarbalance-root'>
        <label>No balance</label>
      </div>
    )
  }

  const onViewDetails = () => {
    dispatch(showTab('billing#balance'))
    navigate('/billing');
  };

  return (
    <div className='sidebarbalance-root'>
      <label className='sidebarbalance-label'>Balance</label>
      <h2 className='sidebarbalance-value'>{balance.currency} {balance.amount.toFixed(2)}</h2>

      <div className='sidebarbalance-action-container'>
        { showViewDetails && <OutlineButtonSmall onClick={onViewDetails}>View Details</OutlineButtonSmall> }
        { showTopUp && <OutlineButtonSmall onClick={() => window.alert('Please contact us for top ups')}>Top Up</OutlineButtonSmall> }
      </div>
    </div>
  )
};

export default Component;