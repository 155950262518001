import SidebarLayout from '../index';

const CustomLayout = ({ children }) => {
  const sidebarItems = [
    { type: 'item', name: 'Clients', url: '/admin/clients' },
    { type: 'item', name: 'Users', url: '/admin/users' },
    { type: 'item', name: 'Pricing' },
  ];

  return (
    <SidebarLayout sidebarItems={sidebarItems}>
      {children}
    </SidebarLayout>
  )
};

export default CustomLayout;