import './style.css';

const Layout = ({ children }) => {
  return (
    <div className="content-layout-root">
      { children }
    </div>
  )
};

export default Layout;