import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BackContentLayout from '../../../layouts/backContentLayout';

import Loading from '../../../components/loading';
import OutlineButtonSmall from '../../../components/outlineButtonSmall';

import UserCardView from '../../../components/userCardView';
import UserCardEdit from '../../../components/userCardEdit';
import UserRoleCardEdit from '../../../components/userRoleCardEdit';

import ClientCardView from '../../../components/clientCardView';
import ClientCardNew from '../../../components/clientCardNew';

import { showPanel, closePanel } from '../../../services/panel/panelSlice';

import UserUtils from '../../../utils/adminUsers';
import ClientUtils from '../../../utils/adminClients';

import './style.css';

const LOAD_COUNT = 300;


const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [user, setUser] = useState(null);
  
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingUserRoles, setLoadingUserRoles] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);

  const [userRoles, setUserRoles] = useState([]);
  const [clients, setClients] = useState([]);


  const loadUser = useCallback(async () => {
    setLoadingUser(true);
    const userResponse = await UserUtils.getUser(userId);
    setLoadingUser(false);

    const { ok, status, body } = userResponse;
    if (ok) {
      setUser(body);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [userId, setUser, setLoadingUser, navigate]);

  const updateUser = useCallback(async (editableUser) => {
    const userResponse = await UserUtils.updateUser(editableUser.id, editableUser.firstName, editableUser.lastName, editableUser.organization, editableUser.email);

    const { ok, status } = userResponse;
    if (ok) {
      await loadUser();
      dispatch(closePanel());
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [loadUser, dispatch, navigate]);

  const listNextUserRoles = useCallback(async (loadCount, loadOffset) => {
    setLoadingUserRoles(true);
    const multiUserRoleResponse = await UserUtils.listUserRoles(userId);
    setLoadingUserRoles(false);

    const { ok, status, body } = multiUserRoleResponse;
    if (ok) {
      const { roles: responseUserRoles } = body;
      setUserRoles(responseUserRoles);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [userId, setUserRoles, setLoadingUserRoles, navigate]);

  const listNextClients = useCallback(async (loadCount, loadOffset) => {
    setLoadingClients(true);
    const multiClientResponse = await ClientUtils.listUserClients(userId);
    setLoadingClients(false);

    const { ok, status, body } = multiClientResponse;
    if (ok) {
      const { clients: responseClients } = body;
      setClients(responseClients);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [userId, setClients, setLoadingClients, navigate]);

  const addUserRole = useCallback(async (role) => {
    const userRoleResponse = await UserUtils.addUserRole(userId, role);

    const { ok, status } = userRoleResponse;
    if (ok) {
      await listNextUserRoles(LOAD_COUNT, 0);
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [userId, listNextUserRoles, navigate]);

  const removeUserRole = useCallback(async (userRole) => {
    const confirmRemove = window.confirm(`Are you sure you would like to remove user role ${userRole.role} for ${user.firstName} ${user.lastName} [${userId}]?`);
    if (confirmRemove) {
      const userRoleResponse = await UserUtils.removeUserRole(userId, userRole.role);

      const { ok, status } = userRoleResponse;
      if (ok) {
        await listNextUserRoles(LOAD_COUNT, 0);
        return;
      }

      if (status === 401 || status === 403) {
        navigate('/login');
        return;
      }
    }
  }, [userId, user, listNextUserRoles, navigate]);

  const createClient = useCallback(async (editableClient) => {
    const clientResponse = await ClientUtils.createClient(userId, editableClient.name);

    const { ok, status } = clientResponse;
    if (ok) {
      await listNextClients(LOAD_COUNT, 0);
      dispatch(closePanel());
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [userId, listNextClients, dispatch, navigate]);

  const showUserEditPanel = useCallback(() => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <UserCardEdit user={user} onUpdate={updateUser} />
      ),
    }));
  }, [dispatch, user, updateUser]);

  const showClientNewPanel = useCallback(() => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <UserRoleCardEdit userRoles={userRoles} onAddRole={addUserRole} onRemoveRole={removeUserRole} availableRoles={UserUtils.getAvailableRoles()} />
      ),
    }));
  }, [dispatch, userRoles, addUserRole, removeUserRole]);

  const showUserRoleEditPanel = useCallback(() => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <ClientCardNew onCreate={createClient} />
      ),
    }));
  }, [dispatch, createClient]);


  useEffect(() => {
    console.log(`User ID: ${userId}`);
    console.log(`Loading user - ${userId}`);
    loadUser();

    console.log(`Loading user roles - ${userId}`);
    listNextUserRoles(LOAD_COUNT, 0);

    console.log(`Loading user clients - ${userId}`);
    listNextClients(LOAD_COUNT, 0);
  }, [userId, loadUser, listNextUserRoles, listNextClients]);

  return (
    <BackContentLayout>
      <h1>User</h1>
      <Loading loading={loadingUser} />
      { !loadingUser && <UserCardView user={user} onClick={showUserEditPanel} /> }

      <h1>Client</h1>
      <OutlineButtonSmall onClick={showClientNewPanel}>New Client</OutlineButtonSmall>
      <Loading loading={loadingClients} />
      { !loadingClients && clients.map(client => <ClientCardView client={client} key={client.id} onClick={() => navigate(`/admin/clients/${client.id}`)} />) }

      <h1>Roles</h1>
      <OutlineButtonSmall onClick={() => showUserRoleEditPanel}>New Role</OutlineButtonSmall>
      <Loading loading={loadingUserRoles} />
      <div className='page-user-role-container' onClick={showUserRoleEditPanel}>
        { !loadingUserRoles && userRoles
          .sort((a, b) => a.role.localeCompare(b.role))
          .map(userRole => <div className='page-user-role-item' key={userRole.id}>• { userRole.role }</div>) }
      </div>
    </BackContentLayout>
  )
};

export default Page;