import './style.css';

const Component = ({ button, children }) => {
  return (
    <div className='dropdown'>
      <div className='dropbtn'>{ button }</div>
      <div className='dropdown-content'>
        { children }
      </div>
    </div>
  )
};

export default Component;