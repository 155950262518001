import { useState } from 'react';

import './style.css';

const MessagingSmsCardSubmit = ({ onChange, onSubmit }) => {
  const [editableSms, setEditableSms] = useState({
    sender: 'Commlab',
    recipient: '',
    body: '',
    reference: '',
  });

  const triggerSubmit = () => {
    const validationErrorMessage = getValidationErrorMessage();
    if (validationErrorMessage != null) {
      alert(validationErrorMessage);
      return;
    }

    if (onSubmit != null) {
      onSubmit(JSON.parse(JSON.stringify(editableSms)));
    }

    setEditableSms({
      sender: 'Commlab',
      recipient: '',
      body: '',
      reference: '',
    });
  }

  const triggerUpdate = (e, key) => {
    setEditableSms(prevUser => ({ ...prevUser, [key]: e.target.value}));
    if (onChange != null) {
      onChange({ ...editableSms, [key]: e.target.value});
    }
  }

  const getValidationErrorMessage = () => {
    if (editableSms.sender == null || editableSms.sender === '') {
      return 'Sender cannot be empty';
    }

    if (editableSms.recipient == null || editableSms.recipient === '') {
      return 'Recipient cannot be empty';
    }

    if (editableSms.body == null || editableSms.body === '') {
      return 'Body cannot be empty';
    }

    return null;
  }

  return (
    <div className='messagingsmscardsubmit-root'>
      <div className='usermessagingsmscardsubmitcardedit-field'>
        <label className='messagingsmscardsubmit-label'>Phone Number with Country Code: (e.g. +6581231234)</label>
        <input type='text' className='messagingsmscardsubmit-input' onChange={e => triggerUpdate(e, 'recipient')} value={editableSms.recipient || ''} />
      </div>
      
      <div className='messagingsmscardsubmit-field'>
        <label className='messagingsmscardsubmit-label'>Body:</label>
        <input type='text' className='messagingsmscardsubmit-input' onChange={e => triggerUpdate(e, 'body')} value={editableSms.body || ''} />
      </div>
      
      <div className='messagingsmscardsubmit-field'>
        <label className='messagingsmscardsubmit-label'>Reference:</label>
        <input type='text' className='messagingsmscardsubmit-input' onChange={e => triggerUpdate(e, 'reference')} value={editableSms.reference || ''}/>
      </div>

      <div className='messagingsmscardsubmit-field'>
        <button className='messagingsmscardsubmit-button' onClick={() => triggerSubmit()}>Send</button>
      </div>
    </div>
  );
}

export default MessagingSmsCardSubmit;