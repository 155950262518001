import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthUtil from '../../../utils/auth';
import './style.css';

const Page = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = () => {
    AuthUtil.login(email, password);

    navigate(`/`);
  }

  const register = () => {
    navigate(`/register`);
  }

  return (
    <div className='login-outer'>
      <div className='login-inner'>
        <h1 className='login-header'>Login to CommHub</h1>

        <div className='login-field'>
          <label className='login-label'>Email</label>
          <input className='login-input-text' type='text' id='email' onChange={e => setEmail(e.target.value)} />
        </div>

        <div className='login-field'>
          <label className='login-label'>Password</label>
          <input className='login-input-text' type='password' id='password' onChange={e => setPassword(e.target.value)} />
        </div>

        <button type='button' className='login-button clickable' onClick={login}>Login</button>
        <button type='button' className='login-text-button clickable' onClick={register}>Register new user</button>
      </div>
    </div>
  )
};

export default Page;