import './style.css';

const Page = () => {
  return (
    <div className="balance-topup-history-root">
      <h2>Top-up History</h2>
    </div>
  )
};

export default Page;