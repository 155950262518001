import './style.css';

const TextButton = ({ onClick, children }) => {
  return (
    <button className='button-text-root' onClick={onClick}>
      { children }
    </button>
  );
}

export default TextButton;