import './style.css';

const Dropdown = ({ disabled, onChange, selectedValue, values, disabledText = 'No options available' }) => {
  const triggerOnChange = (e) => {
    const key = e.target.value;
    
    let value = null;
    for (let i = 0; i < values.length; i += 1) {
      if (key === values[i].key) {
        value = values[i];
        break;
      }
    }

    if (onChange) {
      onChange(value);
    }
  }

  const createChildOption = (value) => {
    const { key, text } = value;
    return <option key={key} value={key}>{text}</option>;
  }

  if (disabled) {
    return (
      <select disabled aria-readonly className='select-disabled'
        value='disabled'>
          { createChildOption({ key: 'disabled', text: disabledText}) }
      </select>
    );
  }

  return (
    <select className='select' 
      onChange={e => triggerOnChange(e)}
      value={(selectedValue && selectedValue.key) || ''}>
      { values.map(createChildOption) }
    </select>
  );
}

export default Dropdown;