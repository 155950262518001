const setItem = (key, value) => {
  localStorage.setItem(key, value);
}

const getItem = (key, defaultValue) => {
  const value = localStorage.getItem(key);
  return value || defaultValue;
}

const clearItem = (key) => localStorage.removeItem(key);

const StoreUtil = {
  setItem,
  getItem,
  clearItem,
};

export default StoreUtil;
