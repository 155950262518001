import './style.css';

const NavBar = ({ children }) => {

  return (
    <div className='navbar-root'>
      { children }
    </div>
  );
}

export default NavBar;