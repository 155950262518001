import { useState } from 'react';

import TextButton from '../textButton';

import './style.css';

const ClientCardEdit = ({ client, onUpdate, onCancel }) => {
  const [editableClient, setEditableClient] = useState(client);

  const triggerCancel = () => {
    if (onCancel != null) {
      onCancel();
    }
  }

  if (client == null) {
    return <div>Null Client</div>
  }

  return (
    <div className='clientcardedit-root'>
      <div className='clientcardedit-field'>
        <label className='clientcardedit-label'>Name:</label>
        <input type='text' className='clientcardedit-input' onChange={e => setEditableClient(prevClient => ({ ...prevClient, name: e.target.value}))} value={editableClient.name || ''} />
      </div>
      
      <div className='clientcardedit-field'>
        <label className='clientcardedit-label-small'>Client ID:&nbsp;{editableClient.id || 'N/A'}</label>
      </div>
      
      <div className='clientcardedit-field'>
        <label className='clientcardedit-label-small'>User ID:&nbsp;{editableClient.userId || 'N/A'}</label>
      </div>

      <div className='clientcardedit-field'>
        <button className='clientcardedit-button' onClick={() => onUpdate(editableClient)}>Update</button>
        <TextButton onClick={triggerCancel}>Cancel</TextButton>
      </div>
    </div>
  );
}

export default ClientCardEdit;