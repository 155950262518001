import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MessagingSmsCardSubmit from '../../../../components/messagingSmsCardSubmit';
import Loading from '../../../../components/loading';

import MessageUtil from '../../../../utils/messages';

import './style.css';

const Page = () => {
  const navigate = useNavigate();
  const client = useSelector(state => state.client.currentClient);

  const [loading, setLoading] = useState(false);
  const [editableSms, setEditableSms] = useState({
    sender: 'Commlab',
    recipient: '',
    body: '',
    reference: '',
  });

  const sendSms = useCallback(async (value) => {
    setLoading(true);
    const smsResponse = await MessageUtil.createSms(
      client.id,
      value.sender, value.recipient, value.body, value.reference,
    );
    setLoading(false);

    const { ok, status, body } = smsResponse;
    if (ok) {
      alert('SMS Sent');
      return;
    }

    if (status === 402) {
      alert('Failed to send. Not enough Balance.');
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }

    console.error(body);
  }, [setLoading, navigate, client]);

  const onSmsCardChange = (value) => {
    setEditableSms(value);
  }

  const onSmsCardSubmit = (value) => {
    // console.log(value);
    sendSms(value);
  }

  return (
    <div className='messaging-sms-root'>
      <h1 className='messaging-sms-header'>SMS</h1>

      <div className='messaging-sms-container'>

        <div className='messaging-sms-container-item'>
          { loading && <Loading /> }
          { !loading && <MessagingSmsCardSubmit onSubmit={onSmsCardSubmit} onChange={onSmsCardChange} /> }
        </div>

        <div className='messaging-sms-container-item'>
          { JSON.stringify(editableSms, null, 2) }
        </div>

      </div>
    </div>
  )
};

export default Page;