import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import BackContentLayout from '../../../layouts/backContentLayout';

import Loading from '../../../components/loading';
import UserCardView from '../../../components/userCardView';

import UserUtils from '../../../utils/adminUsers';

import './style.css';

const LOAD_COUNT = 30;

const Page = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const [loadingUsers, setLoadingUsers] = useState(false);

  const listNextUsers = useCallback(async (loadCount, loadOffset) => {
    setLoadingUsers(true);
    const multiUserResponse = await UserUtils.listUsers(loadCount, loadOffset);
    setLoadingUsers(false);

    const { ok, status, body } = multiUserResponse;
    if (ok) {
      const { users: responseUsers } = body;
      setUsers(responseUsers);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [setUsers, setLoadingUsers, navigate]);

  useEffect(() => {
    console.log('Loading users');
    listNextUsers(LOAD_COUNT, 0);
  }, [listNextUsers]);

  return (
    <BackContentLayout>
      <h1>Users</h1>
      <Loading loading={loadingUsers} />
      { !loadingUsers && users.map(user => <UserCardView 
        user={user} 
        key={user.id} 
        onClick={() => navigate(`/admin/users/${user.id}`)}
        />)}

    </BackContentLayout>
  )
};

export default Page;