import { createSlice } from "@reduxjs/toolkit";

export const tabSlice = createSlice({
  name: 'tab',
  initialState: {
    selectedTab: null,
  },
  reducers: {
    showTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { 
  showTab,
} = tabSlice.actions;

export default tabSlice.reducer;
