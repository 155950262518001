import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import BackContentLayout from '../../../layouts/backContentLayout';
import Loading from '../../../components/loading';
import UserPasswordCardEdit from '../../../components/userCardPasswordEdit';

import UserUtil from '../../../utils/users';

import './style.css';

const Page = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const updateUserPassword = useCallback(async (editableUserPassword) => {
    setLoading(true);
    const updateResponse = await UserUtil.updateMyUserPassword(editableUserPassword.currentPassword, editableUserPassword.newPassword);
    const { ok, status } = updateResponse;
    setLoading(false);

    if (status === 401 || status === 403) {
      navigate('/login');
    }

    if (ok) {
      window.alert('Password changed successfully. Please login again with the new password.');
      navigate('/login');
    } else if (status === 400) {
      // Invalid New Password
      window.alert('Invalid new password. Please try again');
    } else if (status === 422) {
      // Invalid Old Password
      window.alert('Invalid current password. Please try again.');
    }
  }, [navigate]);

  return (
    <BackContentLayout>
      <h1>Change Password</h1>
      <Loading loading={loading} />
      { !loading && <UserPasswordCardEdit onUpdatePassword={updateUserPassword} /> }
    </BackContentLayout>
  )
};

export default Page;