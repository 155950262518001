import { useState } from 'react';

import './style.css';

const DynamicSidebarExpand = ({ name, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className='dynamic-sidebar-expand-root'>
      <div className='dynamic-sidebar-expand-container' onClick={() => setExpanded(expanded => !expanded)}>
        <div className='dynamic-sidebar-expand-label'>{ name }</div>
        { expanded && <span>&#x25B2;</span> }
        { !expanded && <span>&#x25BC;</span> }
      </div>

      <div className='dynamic-sidebar-expand-content'>
        { expanded && children }
      </div>
    </div>
  );
}

export default DynamicSidebarExpand;