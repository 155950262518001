import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ClientCardNew from '../../../components/clientCardNew';
import ClientCardSecretView from '../../../components/clientCardSecretView';
import ClientCardView from '../../../components/clientCardView';

import BalanceCardView from '../../../components/balanceCardView';

import ContentLayout from '../../../layouts/contentLayout';

import { setClients, setCurrentClient } from '../../../services/client/clientSlice';
import { showPanel, closePanel } from '../../../services/panel/panelSlice';

import StoreUtil from '../../../utils/store';
import ClientUtil from '../../../utils/clients';
import BalanceUtil from '../../../utils/balance';


import './style.css';

const MAX_COUNT = 30;

const Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentClient = useSelector(state => state.client.currentClient);
  const clients = useSelector(state => state.client.clients);
  
  const [balance, setBalance] = useState(null);

  const listClients = useCallback(async () => {
    try {
      const clientsResponse = await ClientUtil.listClients(30, 0);
      const { ok, status, body } = clientsResponse;
      if (ok) {
        const { clients } = body; 
        dispatch(setClients(clients));
        return;
      }

      if (status === 401 || status === 403) {
        console.error(`Unauthorized detected`);
        navigate('/login');
        return;
      }

      console.error(`Status ${status} when loading my clients`);;
      console.error(body);
    } catch (error) {
      console.error('Error when loading my clients');
      console.error(error);
      navigate('/login');
    }
  }, [navigate, dispatch]);
  
  const createClient = useCallback(async (editableClient) => {
    dispatch(closePanel())
    
    const clientResponse = await ClientUtil.createClient(editableClient.name);
    const { ok, status, body } = clientResponse;
    if (ok) {
      const client = body;
      dispatch(showPanel({
        allowClosePanel: true,
        panelContent: <ClientCardSecretView client={client} />
      }));

      StoreUtil.setItem('clientId', client.id);
      dispatch(setCurrentClient(client));
      await listClients();
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [dispatch, navigate, listClients]);

  const loadBalance = useCallback(async () => {
    if (currentClient == null) {
      return;
    }

    setBalance(null);
    const balanceResponse = await BalanceUtil.getBalance(currentClient.id);
    const { ok, status, body } = balanceResponse;
    if (ok) {
      setBalance(body);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [navigate, setBalance, currentClient]);

  const showClientCreatePanel = useCallback(() => {
    if (clients && clients.length >= MAX_COUNT) {
      window.alert('You have reach the maximum allowed number of clients.');
      return;
    }

    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: <ClientCardNew onCreate={createClient} />
    }));
  }, [clients, dispatch, createClient]);


  const step1View = () => {
    if (currentClient != null) {
      return step1CompletedView();
    }

    return step1ActionView();
  };

  const step1ActionView = () => {
    return (
      <button className='quickstart-button' onClick={showClientCreatePanel}>Create New Client</button>
    )
  };

  const step1CompletedView = () => {
    return <ClientCardView client={currentClient} />;
  };

  
  const step2View = () => {
    if (balance == null) {
      return <div></div>
    }

    return <BalanceCardView client={currentClient} balance={balance} />;
  };

  const step3View = () => {
    if (currentClient == null || balance == null) {
      return <div></div>
    }

    return (
      <button className='quickstart-button' onClick={() => navigate('/messaging/sms')}>Send your first SMS</button>
    )
  };

  useEffect(() => {
    loadBalance();
  }, [currentClient, loadBalance])

  return (
    <ContentLayout>
      <h1>Quick Start</h1>

      <h2>1. Create a Client</h2>
      <div className='quickstart-section'>{ step1View() }</div>

      <h2>2. Top Up the Balance</h2>
      <div className='quickstart-section'>{ step2View() }</div>

      <h2>3. Send an SMS</h2>
      <div className='quickstart-section'>{ step3View() }</div>
    </ContentLayout>
  )
};

export default Page;