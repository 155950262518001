import DynamicTabBar from '../../components/dynamic-tabbar';

import './style.css';

const Layout = ({ tabs, selectedTab, onTabClicked, children }) => {
  const clickTab = (tab) => {
    if (onTabClicked != null) {
      onTabClicked(tab);
    }
  };

  return (
    <div className="tab-layout-root">
      <DynamicTabBar tabs={tabs}
        selectedTab={selectedTab}
        onTabClick={clickTab} />

      <div className='tab-layout-tab-content'>
        { children }
      </div>
    </div>
  )
};

export default Layout;