import NavBar from '../../components/dynamic-navbar';
import Footer from '../../components/footer';

import './style.css';

const Layout = ({ children, navbarChildren }) => {
  return (
    <div className="navbar-layout-root">
      <NavBar>
        { navbarChildren }
      </NavBar>
      
      <div className="navbar-layout-container">
        { children }
      </div>
      <Footer />
    </div>
  )
};

export default Layout;