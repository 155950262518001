import { useState } from 'react';

import './style.css';

const UserRoleCardEdit = ({ userRoles, onAddRole, onRemoveRole, availableRoles = [] }) => {
  const [editableNewRole, setEditableNewRole] = useState('');

  const addRole = () => {
    if (editableNewRole === '') {
      window.alert('Invalid role. Role must not be empty string.');
      return;
    }

    const role = `${editableNewRole}`;
    onAddRole(role);
    setEditableNewRole('');
  }

  const createRoleView = (userRole) => {
    return (
      <div className='userrolecardedit-item' key={userRole.id}>
        <span className='userrolecardedit-value'>{userRole.role}</span>
        <span className='userrolecardedit-remove' onClick={() => onRemoveRole(userRole)}>✖</span>
      </div>
    )
  }

  return (
    <div className='userrolecardedit-root'>
      <div className='userrolecardedit-field'>
        { userRoles.map(userRole => createRoleView(userRole)) }
      </div>
      
      <div className='userrolecardedit-field'>
        <label className='userrolecardedit-label'>Add New Role</label>
        <select className='userrolecardedit-select' 
          onChange={e => setEditableNewRole(e.target.value)}
          value={editableNewRole || ''}>
          { availableRoles && availableRoles.map(role => (
            <option className='userrolecardedit-option' key={role}>
              { role }
            </option>
          ))}
        </select>
        <input type='text' className='userrolecardedit-input' onChange={e => setEditableNewRole(e.target.value)} value={editableNewRole}/>
        <button className='userrolecardedit-button' onClick={() => addRole()}>Add Role</button>
      </div>
    </div>
  );
}

export default UserRoleCardEdit;