import ApiUtil from './api';

const createSms = async (
  clientId,
  sender, recipient,
  textBody, reference,
) => {
  try {
    const requestData = {
      sender,
      recipient: recipient.replaceAll(/\+|\.|-|\s/g, ""),
      body: textBody,
      reference,
    };
    const responseData = await ApiUtil.post(`/api/clients/${clientId}/messages/sms`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const MessageUtil = {
  createSms,
};

export default MessageUtil;
