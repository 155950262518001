import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthUtil from '../../../utils/auth';
import './style.css';

const Page = () => {
  const navigate = useNavigate();

  const [editableRegistrationRequest, setEditableRegistrationRequest] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    organization: '',
  });

  const register = async () => {
    if (editableRegistrationRequest.password !== editableRegistrationRequest.passwordConfirm) {
      window.alert('Passwords do not match.');
      return;
    }

    await AuthUtil.register(
      editableRegistrationRequest.email,
      editableRegistrationRequest.password,
      editableRegistrationRequest.firstName,
      editableRegistrationRequest.lastName,
      editableRegistrationRequest.organization,
    );

    navigate(`/login`);
  }

  const login = async () => {
    navigate(`/login`);
  }

  return (
    <div className='register-outer'>
      <div className='register-inner'>
        <h1 className='register-header'>Register on CommHub</h1>

        <div className='register-field'>
          <label className='register-label'>Email</label>
          <input className='register-input-text' type='text' id='email' onChange={e => setEditableRegistrationRequest(prevValue => ({ ...prevValue, email: e.target.value}))} />
        </div>

        <div className='register-field'>
          <label className='register-label'>Password</label>
          <input className='register-input-text' type='password' id='password' onChange={e => setEditableRegistrationRequest(prevValue => ({ ...prevValue, password: e.target.value}))} />
        </div>

        <div className='register-field'>
          <label className='register-label'>Confirm Password</label>
          <input className='register-input-text' type='password' id='passwordConfirm' onChange={e => setEditableRegistrationRequest(prevValue => ({ ...prevValue, passwordConfirm: e.target.value}))} />
        </div>

        <div className='register-field'>
          <label className='register-label'>First Name</label>
          <input className='register-input-text' type='text' id='firstName' onChange={e => setEditableRegistrationRequest(prevValue => ({ ...prevValue, firstName: e.target.value}))} />
        </div>

        <div className='register-field'>
          <label className='register-label'>Last Name</label>
          <input className='register-input-text' type='text' id='lastName' onChange={e => setEditableRegistrationRequest(prevValue => ({ ...prevValue, lastName: e.target.value}))} />
        </div>

        <div className='register-field'>
          <label className='register-label'>Organization (optional)</label>
          <input className='register-input-text' type='text' id='organization' onChange={e => setEditableRegistrationRequest(prevValue => ({ ...prevValue, organization: e.target.value}))} />
        </div>

        <button type='button' className='register-button clickable' onClick={register}>Register</button>
        <button type='button' className='login-text-button clickable' onClick={login}>Go to Login</button>
      </div>
    </div>
  )
};

export default Page;