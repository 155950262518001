import ApiUtil from './api';

const getAvailableDeliveryMethods = () => {
  return [
    'VONAGE_SMS',
    // 'VONAGE_SMPP',
    'MELROSE_SMPP',
    'WORLD_SPECIAL',
  ];
}

const createRoute = async (
  userId, clientId,
  deliveryMethod, weight,
) => {
  try {
    const requestData = {
      userId,
      clientId,
      deliveryMethod,
      weight,
    };
    const responseData = await ApiUtil.post(`/api/admin/routes/split`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const updateRoute = async (
  routeSplitId, deliveryMethod, weight,
) => {
  try {
    const requestData = { deliveryMethod, weight };
    const responseData = await ApiUtil.put(`/api/admin/routes/split/${routeSplitId}`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listUserRoutes = async (userId) => {
  try {
    const requestQuery = { userId };
    const responseData = await ApiUtil.get(`/api/admin/routes/split`, requestQuery, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listClientRoutes = async (clientId) => {
  try {
    const requestQuery = { clientId };
    const responseData = await ApiUtil.get(`/api/admin/routes/split`, requestQuery, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listRoutes = async () => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/routes/split`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getRoute = async (routeSplitId) => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/routes/split/${routeSplitId}`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const deleteRoute = async (routeSplitId) => {
  try {
    const responseData = await ApiUtil.delete(`/api/admin/routes/split`, null, {}, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const MessageUtil = {
  createRoute,
  updateRoute,
  
  getRoute,
  deleteRoute,

  listRoutes,
  listUserRoutes,
  listClientRoutes,

  getAvailableDeliveryMethods,
};

export default MessageUtil;
