import './style.css';

const Loading = ({ loading = false }) => {
  return (
    <div className={ loading ? "loading-root" : "loading-none"}>
      <img src="/loading.gif" width="100" height="100" alt="loading" />
    </div>
  )
};

export default Loading;