import ApiUtil from './api';

const getBalance = async (clientId) => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/clients/${clientId}/balances/default`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const giveFreeCredit = async (clientId, balanceId, amount) => {
  try {
    const data = { amount };
    const responseData = await ApiUtil.post(`/api/admin/clients/${clientId}/balances/${balanceId}/freecredit`, null, data, false);
    return responseData
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const BalanceUtil = {
  getBalance,
  giveFreeCredit,
};

export default BalanceUtil;
