import { createSlice } from "@reduxjs/toolkit";

export const panelSlice = createSlice({
  name: 'panel',
  initialState: {
    isShowPanel: false,
    allowClosePanel: true,
    panelContent: null,
  },
  reducers: {
    closePanel: (state) => {
      state.isShowPanel = false;
      state.allowClosePanel = true;
      state.panelContent = null;

    },

    showPanel: (state, action) => {
      state.isShowPanel = true;
      state.allowClosePanel = action.payload.allowClosePanel;
      state.panelContent = action.payload.panelContent;
    },
  },
});

export const { 
  closePanel,
  showPanel,
} = panelSlice.actions;

export default panelSlice.reducer;
