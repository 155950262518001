import DynamicTabBarItem from '../dynamic-tabbar-item';

import './style.css';

const Layout = ({ tabs, selectedTab, onTabClick }) => {
  const clickTab = (tab) => {
    if (onTabClick != null) {
      onTabClick(tab);
    }
  }

  return (
    <div className="dynamic-tabbar-root">
      { tabs.map(tab => (
        <DynamicTabBarItem key={tab.id} 
          selected={selectedTab === tab.id} 
          onTabClick={() => clickTab(tab)}>
          { tab.name }
        </DynamicTabBarItem>
      ))}
    </div>
  )
};

export default Layout;