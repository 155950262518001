import './style.css';

const OutlineButton = ({ onClick, children }) => {
  return (
    <button className='button-outline-root' onClick={onClick}>
      { children }
    </button>
  );
}

export default OutlineButton;