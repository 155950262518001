import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loading from '../../../components/loading';
import ClientCardView from '../../../components/clientCardView';
import ClientCardEdit from '../../../components/clientCardEdit';
import ClientCardRouteSplitNew from '../../../components/clientCardRouteSplitNew';
import ClientCardRouteSplitView from '../../../components/clientCardRouteSplitView';
import ClientCardRouteSplitEdit from '../../../components/clientCardRouteSplitEdit';
import AdminClientCardBalanceView from '../../../components/adminBalanceCardView';
import AdminBalanceCardFreeCredit from '../../../components/adminBalanceCardFreeCredit';

import OutlineButtonSmall from '../../../components/outlineButtonSmall';

import BackContentLayout from '../../../layouts/backContentLayout';

import { showPanel, closePanel } from '../../../services/panel/panelSlice';

import ClientUtils from '../../../utils/adminClients';
import RouteUtils from '../../../utils/adminRoutes';
import BalanceUtils from '../../../utils/adminBalance';

import './style.css';

const LOAD_COUNT = 30;

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const [client, setClient] = useState(null);
  const [routeSplits, setRouteSplits] = useState([]);
  const [balance, setBalance] = useState(null);
  
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingRouteSplits, setLoadingRouteSplits] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);

  const loadClient = useCallback(async () => {
    setLoadingClient(true);
    const clientResponse = await ClientUtils.getClient(clientId);
    setLoadingClient(false);

    const { ok, status, body } = clientResponse;
    if (ok) {
      setClient(body);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [navigate, clientId, setClient, setLoadingClient]);

  const listNextRouteSplits = useCallback(async (loadCount, loadOffset) => {
    setLoadingRouteSplits(true);
    const multiRouteSplitResponse = await RouteUtils.listClientRoutes(clientId, loadCount, loadOffset);
    setLoadingRouteSplits(false);

    const { ok, status, body } = multiRouteSplitResponse;
    if (ok) {
      const { routes: responseRouteSplits } = body;
      setRouteSplits(responseRouteSplits);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [navigate, clientId, setRouteSplits, setLoadingRouteSplits]);

  const loadBalance = useCallback(async () => {
    setLoadingBalance(true);
    const balanceResponse = await BalanceUtils.getBalance(clientId);
    setLoadingBalance(false);

    const { ok, status, body } = balanceResponse;
    if (ok) {
      setBalance(body);
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [clientId, navigate, setBalance, setLoadingBalance]);

  const updateClient = useCallback(async (editableClient) => {
    const clientResponse = await ClientUtils.updateClient(editableClient.id, editableClient.name);
    const { ok, status } = clientResponse;
    if (ok) {
      await loadClient();
      dispatch(closePanel());
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [loadClient, dispatch, navigate]);

  const createRouteSplit = useCallback(async (routeSplit) => {
    const routeResponse = await RouteUtils.createRoute(client.userId, client.id, routeSplit.deliveryMethod, routeSplit.weight);
    const { ok, status } = routeResponse;
    if (ok) {
      await listNextRouteSplits(LOAD_COUNT, 0);
      dispatch(closePanel());
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [client, listNextRouteSplits, dispatch, navigate]);

  const updateRouteSplit = useCallback(async (routeSplit) => {
    const routeResponse = await RouteUtils.updateRoute(routeSplit.id, routeSplit.deliveryMethod, routeSplit.weight);
    const { ok, status } = routeResponse;
    if (ok) {
      await listNextRouteSplits(LOAD_COUNT, 0);
      dispatch(closePanel());
      return;
    }

    if (status === 401 || status === 403) {
      navigate('/login');
      return;
    }
  }, [listNextRouteSplits, dispatch, navigate]);

  const deleteRouteSplit = useCallback(async (routeSplit) => {
    const confirmDelete = window.confirm("Are you sure you want to delete the route split?");
    if (confirmDelete) {
      const routeResponse = await RouteUtils.deleteRoute(routeSplit.id);
      const { ok, status } = routeResponse;
      if (ok) {
        await listNextRouteSplits(LOAD_COUNT, 0);
        dispatch(closePanel());
        return;
      }

      if (status === 401 || status === 403) {
        navigate('/login');
        return;
      }
    }
    
    dispatch(closePanel());
  }, [listNextRouteSplits, dispatch, navigate]);

  const submitFreeCredit = useCallback(async (amount) => {
    const confirmFreeCredit = window.confirm(`Are you sure you want to give ${amount} free credit to the client?`);
    if (confirmFreeCredit) {
      const balanceResponse = await BalanceUtils.giveFreeCredit(clientId, balance.id, amount);
      const { ok, status } = balanceResponse;
      if (ok) {
        await loadBalance();
        dispatch(closePanel());
        return;
      }

      if (status === 401 || status === 403) {
        navigate('/login');
        return;
      }

    }

    dispatch(closePanel());
  }, [clientId, balance, loadBalance, dispatch, navigate]);

  const showClientEditPanel = useCallback(() => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <ClientCardEdit client={client} onUpdate={updateClient} onCancel={() => dispatch(closePanel())} />
      ),
    }));
  }, [dispatch, client, updateClient]);

  const showRouteSplitNewPanel = useCallback(() => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <ClientCardRouteSplitNew onCreate={createRouteSplit} availableDeliveryMethods={RouteUtils.getAvailableDeliveryMethods()} />
      ),
    }));
  }, [dispatch, createRouteSplit]);

  const showRouteSplitEditPanel = useCallback((routeSplit) => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <ClientCardRouteSplitEdit onUpdate={updateRouteSplit} onDelete={deleteRouteSplit} availableDeliveryMethods={RouteUtils.getAvailableDeliveryMethods()} routeSplit={routeSplit} />
      ),
    }));
  }, [dispatch, updateRouteSplit, deleteRouteSplit]);

  const showBalanceFreeCreditPanel = useCallback(() => {
    dispatch(showPanel({
      allowClosePanel: true,
      panelContent: (
        <AdminBalanceCardFreeCredit onSubmit={submitFreeCredit} />
      ),
    }));
  }, [dispatch, submitFreeCredit]);

  useEffect(() => {
    console.log(`Client ID: ${clientId}`);
    console.log(`Loading client - ${clientId}`);
    loadClient();
    loadBalance();
    listNextRouteSplits(LOAD_COUNT, 0);
  }, [clientId, loadClient, loadBalance, listNextRouteSplits]);

  return (
    <BackContentLayout>
      <h1>Client</h1>
      <Loading loading={loadingClient} />
      { !loadingClient && <ClientCardView client={client} onClick={showClientEditPanel} /> }

      <h1>Balance</h1>
      <Loading loading={loadingBalance} />
      { !loadingBalance && <AdminClientCardBalanceView balance={balance} onClick={showBalanceFreeCreditPanel} /> }

      <h1>Route Splits</h1>
      <OutlineButtonSmall onClick={showRouteSplitNewPanel}>New Route Split</OutlineButtonSmall>
      <Loading loading={loadingRouteSplits} />
      { !loadingRouteSplits && routeSplits.map(routeSplit => <ClientCardRouteSplitView routeSplit={routeSplit} onClick={() => showRouteSplitEditPanel(routeSplit)} />)}

    </BackContentLayout>
  )
};

export default Page;