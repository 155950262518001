import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { setMyUser } from '../../services/user/userSlice';

import PanelLayout from '../../layouts/panelLayout';
import AdminSidebarLayout from '../../layouts/sidebarLayout/admin';
import AdminNavbarLayout from '../../layouts/navbarLayout/admin';

import AuthUtil from '../../utils/auth';
import UserUtil from '../../utils/users';

import './style.css';

const Page = () => {  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadMyUser = useCallback(async () => {
    try {
      const myUserResponse = await UserUtil.getMyUser();
      const { ok, status, body } = myUserResponse;
      if (ok) {
        dispatch(setMyUser(body));
        return;
      }

      if (status === 401 || status === 403) {
        console.error(`Unauthorized detected`);
        navigate('/login');
        return;
      }

      console.error(`Status ${status} when loading my user`);;
      console.error(body);
    } catch (error) {
      console.error('Error when loading my user');
      console.error(error);
      navigate('/login');
    }
  }, [navigate, dispatch]);
  

  useEffect(() => {
    const isLoggedIn = AuthUtil.isLoggedIn();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    try {
      // Load User
      loadMyUser();
    } catch (error) {
      console.error(error);
      return;
    }
  }, [navigate, loadMyUser]);

  return (
    <PanelLayout>
      <AdminNavbarLayout>
        <AdminSidebarLayout>
          <Outlet />
        </AdminSidebarLayout>
      </AdminNavbarLayout>
    </PanelLayout>
  )
};

export default Page;