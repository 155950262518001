import './style.css';

const DynamicSidebarItem = ({ name, onItemClick }) => {
  return (
    <div className='dynamic-sidebar-item-root' 
        onClick={() => onItemClick()}>
        {name}
    </div>
  );
}

export default DynamicSidebarItem;