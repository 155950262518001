import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { setMyUser } from '../../services/user/userSlice';
import { setClients, setCurrentClient } from '../../services/client/clientSlice';

import PanelLayout from '../../layouts/panelLayout';
import MainSidebarLayout from '../../layouts/sidebarLayout/main';
import MainNavbarLayout from '../../layouts/navbarLayout/main';

import StoreUtil from '../../utils/store';
import AuthUtil from '../../utils/auth';
import UserUtil from '../../utils/users';
import ClientUtil from '../../utils/clients';

import './main.css';

const Page = () => {  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const savedClientId = StoreUtil.getItem('clientId');

  const loadMyUser = useCallback(async () => {
    try {
      const myUserResponse = await UserUtil.getMyUser();
      const { ok, status, body } = myUserResponse;
      if (ok) {
        dispatch(setMyUser(body));
        return;
      }

      if (status === 401 || status === 403) {
        console.error(`Unauthorized detected`);
        navigate('/login');
        return;
      }

      console.error(`Status ${status} when loading my user`);;
      console.error(body);
    } catch (error) {
      console.error('Error when loading my user');
      console.error(error);
      navigate('/login');
    }
  }, [navigate, dispatch]);

  const listMyClients = useCallback(async () => {
    try {
      const clientsResponse = await ClientUtil.listClients(30, 0);
      const { ok, status, body } = clientsResponse;
      if (ok) {
        const { clients } = body; 
        dispatch(setClients(clients));

        if (clients == null || clients.length <= 0) {
          // No client to pick
          dispatch(setCurrentClient(null));
          StoreUtil.clearItem('clientId');

          navigate('/quickstart');
          return;
        }

        // Pick Client
        let selectedClient = clients[0];
        if (savedClientId != null && savedClientId !== '') {
          for (let i = 0; i < clients.length; i += 1) {
            const client = clients[i];
            if (client.id === savedClientId) {
              // Found
              selectedClient = client;
            }
          }
        }
        dispatch(setCurrentClient(selectedClient));
        StoreUtil.setItem('clientId', selectedClient.id);
        return;
      }

      if (status === 401 || status === 403) {
        console.error(`Unauthorized detected`);
        navigate('/login');
        return;
      }

      console.error(`Status ${status} when loading my clients`);;
      console.error(body);
    } catch (error) {
      console.error('Error when loading my clients');
      console.error(error);
      navigate('/login');
    }
  }, [navigate, dispatch, savedClientId]);
  

  useEffect(() => {
    const isLoggedIn = AuthUtil.isLoggedIn();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    try {
      // Load User
      loadMyUser();

      // List Clients
      listMyClients();
    } catch (error) {
      console.error(error);
      return;
    }
  }, [navigate, loadMyUser, listMyClients]);

  return (
    <PanelLayout>
      <MainNavbarLayout>
        <MainSidebarLayout>
          <Outlet />
        </MainSidebarLayout>
      </MainNavbarLayout>
    </PanelLayout>
  )
};

export default Page;