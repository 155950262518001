import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    currentClient: null,
    
    client: null,
    clients: [],
    clientOffset: 0,
  },
  reducers: {
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload;
    },

    setClient: (state, action) => {
      state.client = action.payload;
    },

    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setClientOffset: (state, action) => {
      state.clientOffset = action.payload;
    },
  },
});

export const { 
  setCurrentClient,
  setClient,
  setClients,
  setClientOffset,
} = clientSlice.actions;

export default clientSlice.reducer;
