import { configureStore } from '@reduxjs/toolkit';

import userReducer from './services/user/userSlice';
import clientReducer from './services/client/clientSlice';

import balanceReducer from './services/balance/balanceSlice';

import panelReducer from './services/panel/panelSlice';
import tabReducer from './services/tab/tabSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    client: clientReducer,

    balance: balanceReducer,

    panel: panelReducer,
    tab: tabReducer,
  },
});
