import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Panel from '../../components/panel';

import { closePanel } from '../../services/panel/panelSlice';

import './style.css';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const isShowPanel = useSelector(state => state.panel.isShowPanel);
  const allowClosePanel = useSelector(state => state.panel.allowClosePanel);
  const panelContent = useSelector(state => state.panel.panelContent);

  const onPanelClose = useCallback(() => {
    dispatch(closePanel());
  }, [dispatch]);

  return (
    <div className="panel-layout-root">
      { children }
      { isShowPanel && (
        <Panel onClose={onPanelClose} allowClosePanel={allowClosePanel}>
          { panelContent }
        </Panel>
      )}
    </div>
  )
};

export default Layout;