import './style.css';

const Layout = ({ selected, children, onTabClick }) => {
  const clickTab = () => {
    if (onTabClick != null) {
      onTabClick();
    }
  }

  return (
    <div className="dynamic-tabbar-item-root" onClick={clickTab}>
      <span className='dynamic-tabbar-item-content'>{ children }</span>
      { selected && <div className='dynamic-tabbar-item-selected'></div> }
      { !selected && <div className='dynamic-tabbar-item-not-selected'></div> }
    </div>
  )
};

export default Layout;