import { useState } from 'react';

import './style.css';

const ClientCardNew = ({ onCreate }) => {
  const [editableClient, setEditableClient] = useState({});

  return (
    <div className='clientcardnew-root'>
      <div className='clientcardnew-field'>
        <label className='clientcardnew-label'>Name:</label>
        <input type='text' className='clientcardnew-input' onChange={e => setEditableClient(prevClient => ({ ...prevClient, name: e.target.value}))} value={editableClient.name || ''} />
      </div>

      <div className='clientcardnew-field'>
        <button className='clientcardnew-button' onClick={() => onCreate(editableClient)}>Create</button>
      </div>
    </div>
  );
}

export default ClientCardNew;