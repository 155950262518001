import { useState } from 'react';

import './style.css';

const ClientCardRouteSplitNew = ({ onCreate, availableDeliveryMethods }) => {
  const [editableRouteSplit, setEditableRouteSplit] = useState({
    deliveryMethod: availableDeliveryMethods && availableDeliveryMethods[0],
    weight: 10,
  });

  return (
    <div className='clientcardroutesplitnew-root'>
      <div className='clientcardroutesplitnew-field'>
        <label className='clientcardroutesplitnew-label'>Delivery Method:</label>
        <select className='clientcardroutesplitnew-select' 
          onChange={e => setEditableRouteSplit(prevClient => ({ ...prevClient, deliveryMethod: e.target.value}))}
          value={editableRouteSplit.deliveryMethod || ''}>
          { availableDeliveryMethods && availableDeliveryMethods.map(deliveryMethod => (
            <option className='clientcardroutesplitnew-option' key={deliveryMethod}>
              { deliveryMethod }
            </option>
          ))}
        </select>
      </div>

      <div className='clientcardroutesplitnew-field'>
        <label className='clientcardroutesplitnew-label'>Weight:</label>
        <input type='text' className='clientcardroutesplitnew-input' onChange={e => setEditableRouteSplit(prevClient => ({ ...prevClient, weight: e.target.value}))} value={editableRouteSplit.weight || ''} />
      </div>
      

      <div className='clientcardroutesplitnew-field'>
        <button className='clientcardroutesplitnew-button' onClick={() => onCreate(editableRouteSplit)}>Create</button>
      </div>
    </div>
  );
}

export default ClientCardRouteSplitNew;