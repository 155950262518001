import ApiUtil from './api';

const createClient = async (name) => {
  try {
    const requestData = { name };
    const responseData = await ApiUtil.post('/api/clients', null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const updateClient = async (clientId, name) => {
  try {
    const requestData = { name };
    const responseData = await ApiUtil.put(`/api/clients/${clientId}`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listClients = async (count = 30, offset = 0) => {
  try {
    const query = { count, offset };
    const responseData = await ApiUtil.get(`/api/clients`, query, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getClient = async (clientId) => {
  try {
    const responseData = await ApiUtil.get(`/api/clients/${clientId}`, null, false);
    return responseData;    
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const deleteClient = async (clientId) => {
  try {
    const responseData = await ApiUtil.delete(`/api/clients/${clientId}`, null, false);
    return responseData;    
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const resetClientSecret = async (clientId) => {
  try {
    const requestData = {};
    const responseData = await ApiUtil.post(`/api/clients/${clientId}/resetSecret`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const ClientUtil = {
  createClient,
  updateClient,
  
  listClients,
  getClient,
  deleteClient,

  resetClientSecret,
};

export default ClientUtil;
