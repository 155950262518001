import './style.css';

const AdminBalanceCardView = ({ balance, onClick }) => {
  if (balance == null) {
    return <div>Null Balance</div>
  }

  const {
    id: clientId,
    amount,
    minThreshold,
    dateCreated,
    dateUpdated,
  } = balance;

  return (
    <div className='adminbalancecardview-root' onClick={onClick}>
      <div className='adminbalancecardview-field'>
        <div className='adminbalancecardview-value'>Amount:&nbsp;{amount.toFixed(2)}</div>
      </div>

      <div className='adminbalancecardview-field'>
        <div className='adminbalancecardview-value'>Minimum Threshold:&nbsp;{minThreshold.toFixed(2)}</div>
      </div>
      
      <div className='adminbalancecardview-field'>
        <label className='adminbalancecardview-label-small'>Client ID:&nbsp;{clientId || 'N/A'}</label>
      </div>
      
      <div className='adminbalancecardview-field-small'>
        <label className='adminbalancecardview-label-small'>Created At:&nbsp;{dateCreated}</label>
      </div>
      
      <div className='adminbalancecardview-field-small'>
        <label className='adminbalancecardview-label-small'>Updated At:&nbsp;{dateUpdated}</label>
      </div>
    </div>
  );
}

export default AdminBalanceCardView;