import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCurrentClient } from '../../../services/client/clientSlice';

import NavbarLayout from '../index';
import Dropdown from '../../../components/dropdown';
import NavbarDropdown from '../../../components/navbarDropdown';
import NavbarDropdownItem from '../../../components/navbarDropdownItem';

import AuthUtil from '../../../utils/auth';
import StoreUtil from '../../../utils/store';

import './style.css';

const CustomLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const myUser = useSelector(state => state.user.myUser);
  const currentClient = useSelector(state => state.client.currentClient);
  const clients = useSelector(state => state.client.clients);

  const logout = () => {
    const confirm = window.confirm('Are you sure you want to logout?');
    if (!confirm) {
      return;
    }

    AuthUtil.logout();
    navigate('/login');
  }

  const onClientChange = (value) => {
    const client = value.value;
    StoreUtil.setItem('clientId', client.id);
    dispatch(setCurrentClient(client));
  }

  const navbarChildren = () => [
    <h1 key='navbar-left' className='navbar-label' onClick={() => navigate('/')}>CommHub</h1>,
    <div key='navbar-middle' className='navbar-middle-container'>
      <Dropdown
        disabled={clients == null || clients.length <= 0}
        disabledText='No client'
        onChange={onClientChange}
        selectedValue={ currentClient && { key: currentClient.id, value: currentClient, text: currentClient.name }}
        values={clients.map(client => ({ key: client.id, value: client, text: client.name }))}
        />
    </div>,
    <NavbarDropdown key='navbar-right' button={`Hello ${myUser ? myUser.firstName : 'user'}`}>
      <NavbarDropdownItem onClick={() => navigate('/users/me')}>My User</NavbarDropdownItem>
      <NavbarDropdownItem onClick={() => navigate('/clients')}>My Clients</NavbarDropdownItem>
      <NavbarDropdownItem onClick={() => navigate('/users/me/password')}>Change Password</NavbarDropdownItem>
      <NavbarDropdownItem onClick={() => logout()}>Logout</NavbarDropdownItem>
    </NavbarDropdown>,
  ];

  return (
    <NavbarLayout navbarChildren={navbarChildren()}>
      {children}
    </NavbarLayout>
  )
};

export default CustomLayout;