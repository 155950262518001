import './style.css';

const ClientCardRouteSplitView = ({ routeSplit, onClick }) => {
  const {
    deliveryMethod,
    weight,
    id,
    userId,
    clientId,
  } = routeSplit;

  return (
    <div className='clientcardroutesplitview-root' onClick={() => onClick()}>
      <div className='clientcardroutesplitview-field'>
        <label className='clientcardroutesplitview-label'>Delivery Method:</label>
        <div className='clientcardroutesplitview-value'>{deliveryMethod || 'N/A'}</div>
      </div>
      
      <div className='clientcardroutesplitview-field'>
        <label className='clientcardroutesplitview-label'>Weight:</label>
        <div className='clientcardroutesplitview-value'>{weight || 'N/A'}</div>
      </div>

      
      <div className='clientcardroutesplitview-field'>
        <label className='clientcardroutesplitview-label-small'>Route Split ID:&nbsp;{id || 'N/A'}</label>
      </div>

      
      <div className='clientcardroutesplitview-field'>
        <label className='clientcardroutesplitview-label-small'>Client ID:&nbsp;{clientId || 'N/A'}</label>
      </div>
      
      <div className='clientcardroutesplitview-field'>
        <label className='clientcardroutesplitview-label-small'>User ID:&nbsp;{userId || 'N/A'}</label>
      </div>
    </div>
  );
}

export default ClientCardRouteSplitView;