import ApiUtil from './api';

const getAvailableRoles = () => {
  return [
    // User
    'ROLE_USER_ADMIN',
    'ROLE_USER_LIST',
    'ROLE_USER_CREATE',
    'ROLE_USER_VIEW',
    'ROLE_USER_UPDATE',
    'ROLE_USER_UPDATE_PASSWORD',
    'ROLE_USER_DELETE',

    // User Role
    'ROLE_USERROLE_ADMIN',
    'ROLE_USERROLE_LIST',
    'ROLE_USERROLE_CREATE',
    'ROLE_USERROLE_VIEW',
    'ROLE_USERROLE_UPDATE',
    'ROLE_USERROLE_DELETE',

    // Client
    'ROLE_CLIENT_ADMIN',
    'ROLE_CLIENT_LIST',
    'ROLE_CLIENT_CREATE',
    'ROLE_CLIENT_VIEW',
    'ROLE_CLIENT_UPDATE',
    'ROLE_CLIENT_DELETE',
    'ROLE_CLIENT_UPDATE_SECRET',

    // SMS
    'ROLE_SMS_CREATE',

    // Message
    'ROLE_MESSAGE_ADMIN',
    'ROLE_MESSAGE_LIST',
    'ROLE_MESSAGE_CREATE',
    'ROLE_MESSAGE_VIEW',
    'ROLE_MESSAGE_UPDATE',
    'ROLE_MESSAGE_UPDATE_STATE',
    'ROLE_MESSAGE_DELETE',

    // Delivery Instruction
    'ROLE_DELIVERY_INSTRUCTION_ADMIN',
    'ROLE_DELIVERY_INSTRUCTION_LIST',
    'ROLE_DELIVERY_INSTRUCTION_CREATE',
    'ROLE_DELIVERY_INSTRUCTION_VIEW',
    'ROLE_DELIVERY_INSTRUCTION_UPDATE',
    'ROLE_DELIVERY_INSTRUCTION_DELETE',

    // Delivery Attempt
    'ROLE_DELIVERY_ATTEMPT_ADMIN',
    'ROLE_DELIVERY_ATTEMPT_LIST',
    'ROLE_DELIVERY_ATTEMPT_CREATE',
    'ROLE_DELIVERY_ATTEMPT_VIEW',
    'ROLE_DELIVERY_ATTEMPT_UPDATE',
    'ROLE_DELIVERY_ATTEMPT_DELETE',

    // Route
    'ROLE_ROUTE_ADMIN',
    'ROLE_ROUTE_LIST',
    'ROLE_ROUTE_CREATE',
    'ROLE_ROUTE_VIEW',
    'ROLE_ROUTE_UPDATE',
    'ROLE_ROUTE_DELETE',

    // Pricing
    'ROLE_PRICING_ADMIN',
    'ROLE_PRICING_LIST',
    'ROLE_PRICING_CREATE',
    'ROLE_PRICING_VIEW',
    'ROLE_PRICING_UPDATE',
    'ROLE_PRICING_DELETE',
  ];
}

const updateUser = async (userId, firstName, lastName, organization, email) => {
  try {
    const requestData = { firstName, lastName, organization, email };
    const responseData = await ApiUtil.put(`/api/admin/users/${userId}`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listUsers = async () => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/users`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getUser = async (userId) => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/users/${userId}`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const listUserRoles = async (userId) => {
  try {
    const responseData = await ApiUtil.get(`/api/admin/users/${userId}/roles`, null, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const addUserRole = async (userId, role) => {
  try {
    const requestData = { role };
    const responseData = await ApiUtil.post(`/api/admin/users/${userId}/roles/add`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const removeUserRole = async (userId, role) => {
  try {
    const requestData = { role };
    const responseData = await ApiUtil.put(`/api/admin/users/${userId}/roles/remove`, null, requestData, false);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const UserUtil = {
  updateUser,
  
  listUsers,
  getUser,

  listUserRoles,
  addUserRole,
  removeUserRole,

  getAvailableRoles,
};

export default UserUtil;
