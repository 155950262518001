import { useState } from 'react';

import './style.css';

const AdminBalanceCardFreeCredit = ({ onSubmit }) => {
  const [editableAmount, setEditableAmount] = useState('');

  const triggerSubmit = () => {
    if (onSubmit != null) {
      onSubmit(parseFloat(editableAmount));
    }
  }

  return (
    <div className='adminbalancecardfreecredit-root'>
      
      <div className='adminbalancecardfreecredit-field'>
        <label className='adminbalancecardfreecredit-label'>Amount:</label>
        <input type='text' className='adminbalancecardfreecredit-input' onChange={e => setEditableAmount(e.target.value)} value={editableAmount || ''} />
      </div>

      <div className='adminbalancecardfreecredit-field'>
        <button className='adminbalancecardfreecredit-button' onClick={() => triggerSubmit()}>Submit</button>
      </div>
    </div>
  );
}

export default AdminBalanceCardFreeCredit;